//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

import ImageCopyright from '../Partials/ImageCopyright.vue';

export default {
    name: 'StageHomepage',
    components: { ImageCopyright },
    props: ['data'],
    data() {
        return {
            header: null,
            timeout: 0,
            imagePath: this.$config.pimcoreUrl,
        };
    },
    computed: {
        items() {
            return this.data?.items?.map((item) => {
                return {
                    ...item,
                    image: this.$mapImageData(item?.image?.image),
                    imageMobile: this.$mapImageData(item?.imageMobile?.image),
                };
            });
        },
    },
    mounted() {
        this.header = document.body.querySelector('.header');
        ['scroll', 'resize'].forEach((eventName) => {
            window.addEventListener(eventName, this.handleScrollPos);
        });
        this.handleScrollPos();

        const stageSwiper = new Swiper('.stage-homepage__slider-container--slide', {
            direction: 'horizontal',
            loop: false,
            speed: 750,

            pagination: {
                el: '.stage-homepage__pagination',
                type: 'fraction',
            },

            navigation: {
                nextEl: '.stage-homepage__button--next',
                prevEl: '.stage-homepage__button--prev',
                disabledClass: 'stage-homepage__button--disabled',
            },
        });
    },
    methods: {
        toContent() {
            // scroll to first content
            let screenHeight = window.innerHeight;
            window.scrollTo({
                top: screenHeight,
                left: 0,
                behavior: 'smooth',
            });
        },
        handleScrollPos() {
            if (window.scrollY > 1) {
                if (this.header.classList.contains('header--light')) {
                    this.$nuxt.$emit('header.state', { light: false });
                }
            } else {
                if (!this.header.classList.contains('header--light')) {
                    this.$nuxt.$emit('header.state', { light: true });
                }
            }

            // set image copyright bottom value
            const root = document.documentElement;
            root.style.setProperty(
                '--stage-homepage-navigation-height',
                `${this.$refs.navigation?.getBoundingClientRect().height}px` || 0
            );
        },
    },
};
